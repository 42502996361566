import { render, staticRenderFns } from "./secondStepMore.vue?vue&type=template&id=3be7cb2a&scoped=true"
import script from "./secondStepMore.vue?vue&type=script&lang=js"
export * from "./secondStepMore.vue?vue&type=script&lang=js"
import style0 from "./secondStepMore.vue?vue&type=style&index=0&id=3be7cb2a&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3be7cb2a",
  null
  
)

export default component.exports